header {
   height: 120vh;
   padding-top: 5rem;
   overflow: hidden;
}

.header_container {
   text-align: center;
   height: 100%;
   position: relative;
}

/* CTA  */
.cta {
   margin-top: 2.5rem;
   display: flex;
   gap: 1.2rem;
   justify-content: center;
}

/* SOCIALS  */
.header_socials {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 0.8rem;
   position: absolute;
   left: 0;
   bottom: 12rem;
}

.header_socials::after {
   content: '';
   width: 1px;
   height: 2rem;
   background-color: var(--color-primary);
}

/* ME  */
.me {
   background: linear-gradient(var(--color-primary), transparent);
   width: 22rem;
   height: 32rem;
   position: absolute;
   left: calc(50% - 11rem);
   margin-top: 4rem;
   border-radius: 8rem 8rem 1rem 1rem;
   overflow: hidden;
   padding: 2rem 2rem 2rem 2rem;
}

/* SCROLL DOWN  */
.scroll_down {
   position: absolute;
   right: -2.3rem;
   bottom: 14rem;
   transform: rotate(90deg);
   font-weight: 300;
   font-size: 0.9rem;
}

/* MEDIA QUERIES (MEDIUM SCRREN) */
@media screen and (max-width: 1024px) {
   header {
      height: 80vh;
   }
}

/* MEDIA QUERIES (SMALL SCRREN) */
@media screen and (max-width: 600px) {
   header {
      height: 100vh;
   }

   .header_socials,
   .scroll_down {
      display: none;
   }
}
