.container.contact_container {
   width: 58%;
   display: grid;
   grid-template-columns: 30% 58%;
   gap: 12%;
   margin-top: 5rem !important;
}

.cintact_options {
   display: flex;
   flex-direction: column;
   gap: 1.2rem;
}

.contact_option {
   background: var(--color-primary);
   padding: 1.2rem;
   margin-bottom: 2rem;
   border-radius: 1.2rem;
   text-align: center;
   border: 1px solid transparent;
   transition: var(--transition);
}

.contact_option:hover {
   background: transparent;
   border-color: var(--color-primary);
}

.contact_option-icon {
   font-size: 1.5rem;
   margin-bottom: 0.5rem;
}

.contact_option a {
   margin-top: 0.7rem;
   display: inline-block;
   font-size: 0.8rem;
   color: var(--color-white);
   font-weight: 500;
}

/* Form  */

form {
   display: flex;
   flex-direction: column;
   gap: 1.2rem;
}

input,
textarea {
   width: 100%;
   padding: 1.5rem;
   border-radius: 0.5rem;
   background: transparent;
   border: 2px solid var(--color-primary);
   resize: none;
   color: var(--color-white);
   font-family: 'Poppins', sans-serif;
}

/* MEDIA QUERIES (MEDIUM SCRREN) */
@media screen and (max-width: 1024px) {
   .container.contact_container {
      grid-template-columns: 1fr;
      gap: 2rem;
   }
}

/* MEDIA QUERIES (SMALL SCRREN) */
@media screen and (max-width: 600px) {
   .container.contact_container {
      width: var(--container-width-sm);
   }
}
