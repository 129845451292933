.portfolio_container {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 2.5rem;
   margin-top: 5rem !important;
}

.portfolio_item {
   background: transparent;
   padding: 1.3rem;
   border-radius: 2rem;
   border: 1px solid transparent;
   transition: var(--transition);
   border-color: var(--color-primary);
}

.portfolio_item:hover {
   border-color: var(--color-primary);
   background: var(--color-bg-variant);
}

.portfolio_item-img {
   border-radius: 1.5rem;
   overflow: hidden;
}

.portfolio_item h3 {
   margin: 1.2rem 0 2rem;
}

.portfolio_item-cta {
   display: flex;
   gap: 1rem;
   margin-bottom: 1rem;
}

a.git {
   display: flex;
   margin: 2rem auto;
}

/* MEDIA QUERIES (MEDIUM SCRREN) */
@media screen and (max-width: 1024px) {
   .portfolio_container {
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
   }
}
/* MEDIA QUERIES (SMALL SCRREN) */
@media screen and (max-width: 600px) {
   .portfolio_container {
      grid-template-columns: 1fr;
      gap: 1rem;
   }
}
