.experience_container {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 2rem;
   margin-top: 5rem !important;
}

.experience_container > div {
   background: var(--color-primary);
   padding: 2.4rem 5rem;
   border-radius: 2rem;
   border: 1px solid transparent;
   transition: var(--transition);
}

.experience_container > div:hover {
   background: transparent;
   color: var(--color-primary);
   border-color: var(--color-primary);
   cursor: default;
}

.experience_container > div h3 {
   text-align: center;
   margin-bottom: 2rem;
   color: var(--color-white);
}

.experience_content {
   display: grid;
   grid-template-columns: 1fr 1fr;
   row-gap: 2.7rem;
}

.experience_detail {
   display: flex;
   gap: 1rem;
}

.certificates_detail {
   display: flex;
   gap: 0.5rem;
   margin-bottom: 0.3rem;
}

.certificates_detail a {
   color: var(--color-white);
}

.experience_detail-icon {
   margin-top: 6px;
   color: var(--color-white);
}

.certificates_detail-icon {
   font-size: 1.35rem;
   color: var(--color-white);
}

/* MEDIA QUERIES (MEDIUM SCRREN) */
@media screen and (max-width: 1024px) {
   .experience_container {
      grid-template-columns: 1fr;
   }
   .experience_container > div {
      width: 80%;
      padding: 2rem;
      margin: 0 auto;
   }
   .experience_content {
      padding: 1rem;
   }
}
/* MEDIA QUERIES (SMALL SCRREN) */
@media screen and (max-width: 600px) {
   .experience_container {
      gap: 1rem;
   }
   .experience_container > div {
      width: 100%;
      padding: 2rem 1rem;
   }
}
